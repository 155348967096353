import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getRefunds(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_refunds', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRefund(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_refund', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRefund(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/edit_refund/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRefund(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/delete_refund/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultRefund(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_default_refund', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
