import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t } from '@/@core/libs/i18n/utils'

export default function useRefundList() {
  // Use toast
  const toast = useToast()

  const refRefundListTable = ref(null)

  const msgRefundList = {
    errorfetchRefund: t('Error fetching {module} list', t('Refund')),
    date_time: `${t('Refund')} » ${t('Date')} ${t('Time')},${t('Note')},${t('By')}`,
    coupon: t('Coupon'),
    redeem_datetime_username: `${t('Redeem')} » ${t('Date')} ${t('Time')}/${t('By')}`,
    refid_reference_point: `${t('Redeem')} » ${t('Ref ID')}/${t('Reference')}/${t('Point')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'date_time', sortable: true, label: msgRefundList.date_time },
    { key: 'coupon', sortable: true, label: msgRefundList.coupon },
    { key: 'redeem_datetime_username', sortable: false, label: msgRefundList.redeem_datetime_username },
    { key: 'refid_reference_point', sortable: true, label: msgRefundList.refid_reference_point },
    { key: 'actions', label: msgRefundList.actions },
  ]

  const perPage = ref(10)
  const totalRefunds = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date_time')
  const isSortDirDesc = ref(true)
  const brandFilter = ref(null)
  const merchantFilter = ref(null)
  const codeFilter = ref(null)
  const refidFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refRefundListTable.value ? refRefundListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRefunds.value,
    }
  })

  const refetchData = () => {
    refRefundListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, brandFilter, merchantFilter, codeFilter, refidFilter], () => {
    refetchData()
  })

  const getRefunds = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      brand: brandFilter.value,
      merchant: merchantFilter.value,
      code: codeFilter.value,
      refid: refidFilter.value,
    }
    store
      .dispatch('refund-store/getRefunds', params)
      .then(response => {
        const { refunds, total } = response.data
        callback(refunds)
        totalRefunds.value = total
        localStorage.setItem('filterRefundList', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgRefundList.errorfetchRefund,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getRefunds,
    tableColumns,
    perPage,
    currentPage,
    totalRefunds,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRefundListTable,

    refetchData,

    // Extra Filters
    brandFilter,
    merchantFilter,
    codeFilter,
    refidFilter,
  }
}
